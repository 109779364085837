import { useEffect, useRef } from 'react';
import { VAD_SOCKET_SERVER_URL, VITE_SOCKET_API_URL } from '../configs/config';
import { datadogLogs } from '@datadog/browser-logs';
import useMeeting from 'hooks/useMeeting';
import type { DyteParticipant } from '@dytesdk/web-core';

const CONFIG = {
  SAMPLE_RATE: 48000,
  BUFFER_SIZE: 48000 * 10,
  API_ENDPOINT: VAD_SOCKET_SERVER_URL || 'http://localhost:5000',
  SOCKET_API_ENDPOINT: VITE_SOCKET_API_URL || 'http://localhost:5000',
  DEBUG: true,
  ENERGY_THRESHOLD: 0.002,
  CHUNK_SIZE: 128,
  MIN_NON_ZERO_CHUNKS: 1,
  MIN_ACTIVE_RATIO: 0.05,
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000,
  SETUP_TIMEOUT: 5000
};

const LOCKS = {
  setupLocks: new Map<string, boolean>(),
  cleanupLocks: new Map<string, boolean>(),
};

const withLock = async (
  lockMap: Map<string, boolean>,
  key: string,
  operation: () => Promise<void>,
  classIdParam: string
) => {
  if (lockMap.get(key)) {
    logger.info('Operation locked, skipping', { key, classId: classIdParam });
    return;
  }

  try {
    lockMap.set(key, true);
    await operation();
  } finally {
    lockMap.set(key, false);
  }
};


// New type definitions
type AudioProcessingState = 'idle' | 'setting-up' | 'active' | 'cleaning-up';

interface ParticipantAudioState {
  setupState: AudioProcessingState;
  lastStateChange: number;
  track: MediaStreamTrack | null;
  audioEnabled: boolean;
  lastProcessTime: number;
  setupAttempts: number;
}

// New configuration constants
const STATE_CONFIG = {
  MAX_CLEANUP_TIME: 5000,    // Increased from 2000
  SETUP_DELAY: 500,          // Increased from 200
  STATE_CHANGE_COOLDOWN: 2000 // Increased from 1000
};


const logger = {
  info: (message: string, data?: any) => {
    if (!CONFIG.DEBUG) return;
    datadogLogs.logger.info(`[TalkTime Info] ${message}` + JSON.stringify({ ...data, classId: data?.classId }));
  },
  error: (message: string, error?: any) => {
    if (!CONFIG.DEBUG) return;
    datadogLogs.logger.error(`[TalkTime Error] ${message}` + JSON.stringify({ error: { ...error, classId: error?.classId } }));
  }
};

// Add TypeScript type for processBuffer
type ProcessBufferFunction = (
  participantId: string,
  audioData: Float32Array,
  userType: string,
  userId: string
) => Promise<void>;

export const useStudentTalkTime = () => {
  const meetingContext = useMeeting();
  const { meeting, classId, joinedParticipants } = meetingContext;
  const lastProcessTimeMap = useRef(new Map<string, number>());
  const audioContexts = useRef(new Map<string, AudioContext>());
  const audioProcessors = useRef(new Map<string, AudioWorkletNode>());
  const cleanupInProgress = useRef(new Map<string, boolean>());
  const handledParticipants = useRef(new Set<string>());
  const participantTracks = useRef(new Map<string, MediaStreamTrack>());
  const participantStates = useRef(new Map<string, ParticipantAudioState>());


  const updateParticipantState = (
    participantId: string,
    updates: Partial<ParticipantAudioState>
  ) => {
    const currentState = participantStates.current.get(participantId) || {
      setupState: 'idle',
      lastStateChange: 0,
      track: null,
      audioEnabled: false,
      lastProcessTime: 0,
      setupAttempts: 0
    };

    const newState = {
      ...currentState,
      ...updates,
      lastStateChange: Date.now()
    };

    participantStates.current.set(participantId, newState);
    logger.info('State updated', { participantId, oldState: currentState.setupState, newState: newState.setupState, classId });

    return newState;
  };

  const cleanupParticipantAudio = async (participantId: string) => {
    const state = participantStates.current.get(participantId);
    if (state?.setupState === 'active' && Date.now() - state.lastStateChange < 2000) {
      logger.info('Skipping cleanup - active state too recent', { participantId, classId });
      return;
    }
    return withLock(LOCKS.cleanupLocks, participantId, async () => {
      const state = participantStates.current.get(participantId);

      if (!state || state.setupState === 'cleaning-up') {
        logger.info('Cleanup skipped - invalid state', { participantId, state: state?.setupState, classId });
        return;
      }

      // Even if throttled, immediately disconnect the processor
      const processor = audioProcessors.current.get(participantId);
      if (processor) {
        try {
          processor.disconnect();
          audioProcessors.current.delete(participantId);
        } catch (error) {
          logger.error('Immediate processor disconnect error', { error, participantId, classId });
        }
      }

      if (Date.now() - state.lastStateChange < STATE_CONFIG.STATE_CHANGE_COOLDOWN) {
        logger.info('Cleanup throttled', { participantId, classId });
        return;
      }

      updateParticipantState(participantId, { setupState: 'cleaning-up' });

      const cleanupPromise = new Promise<void>(async (resolve, reject) => {
        try {
          const hasProcessor = audioProcessors.current.has(participantId);
          const hasContext = audioContexts.current.has(participantId);

          if (!hasProcessor && !hasContext) {
            resolve();
            return;
          }

          logger.info('Starting cleanup', { participantId, hasProcessor, hasContext, classId });

          if (hasProcessor) {
            const processor = audioProcessors.current.get(participantId);
            if (processor) {
              try {
                processor.disconnect();
                await new Promise(resolve => setTimeout(resolve, 50));
              } catch (error) {
                logger.error('Processor disconnect error', { error, participantId, classId });
              }
              audioProcessors.current.delete(participantId);
            }
          }

          if (hasContext) {
            const context = audioContexts.current.get(participantId);
            if (context) {
              try {
                if (context.state === 'running') {
                  await context.suspend();
                }
                await context.close();
              } catch (error) {
                logger.error('Context cleanup error', { error, participantId, classId });
              }
              audioContexts.current.delete(participantId);
            }
          }

          resolve();
        } catch (error) {
          reject(error);
        }
      });

      try {
        await Promise.race([
          cleanupPromise,
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Cleanup timeout')), STATE_CONFIG.MAX_CLEANUP_TIME)
          )
        ]);

        updateParticipantState(participantId, {
          setupState: 'idle',
          track: null,
          audioEnabled: false
        });

        logger.info('Cleanup completed', { participantId, classId });
      } catch (error) {
        logger.error('Cleanup failed or timed out', { error, participantId, classId });
        // Force cleanup of references
        audioProcessors.current.delete(participantId);
        audioContexts.current.delete(participantId);
        updateParticipantState(participantId, { setupState: 'idle' });
      }
    }, classId);
  };


  const processBuffer: ProcessBufferFunction = async (
    participantId,
    audioData,
    userType,
    userId
  ) => {
    const processingId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

    logger.info('Starting buffer processing', {
      processingId,
      participantId,
      samples: audioData.length,
      userType,
      userId,
      classId
    });

    try {
      const validParticipants = joinedParticipants
        .map(p => p.name)
        .filter((name): name is string => !!name);

      const payload = {
        audio_data: Array.from(audioData),
        session_id: participantId,
        sample_rate: CONFIG.SAMPLE_RATE,
        user_type: userType,
        class_id: classId,
        user_id: userId,
        participant_names: validParticipants,
        request_id: `${userType}-${classId}-${userId}-${Date.now()}`
      };

      logger.info('Sending request to process-audio', {
        processingId,
        requestId: payload.request_id,
        participantId,
        classId
      });

      const response = await fetch(`${CONFIG.API_ENDPOINT}/process-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: {
        has_transcription?: boolean;
        metrics?: unknown;
      } = await response.json();

      logger.info('Received response from process-audio', {
        processingId,
        requestId: payload.request_id,
        participantId,
        hasTranscription: !!data?.has_transcription,
        hasMetrics: !!data?.metrics,
        classId
      });

      // Send to talktime API instead of socket
      try {
        const talkTimeResponse = await fetch(`${CONFIG.SOCKET_API_ENDPOINT}/v1/talktime`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data,
            participantInfo: {
              userType,
              participantId,
              userId
            },
            timestamp: Date.now()
          })
        });

        if (!talkTimeResponse.ok) {
          throw new Error(`Talk time API error: ${talkTimeResponse.status}`);
        }

        logger.info('Talk time data sent successfully', {
          processingId,
          participantId,
          classId
        });
      } catch (error) {
        logger.error('Talk time API error', {
          processingId,
          error: error instanceof Error ? error.message : String(error),
          participantId,
          classId
        });
      }

    } catch (error) {
      logger.error('Buffer processing error', {
        processingId,
        error: error instanceof Error ? error.message : String(error),
        participantId,
        classId
      });
    }
  };

  // Replace the existing setupAudioProcessing function with this version
  const setupAudioProcessing = async (
    participant: DyteParticipant,
    audioTrack: MediaStreamTrack,
    retryCount = 0
  ): Promise<void> => {
    const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
    const participantId = `${userType}-${classId}-${participant.customParticipantId?.split('-').pop()}`;

    // Add delay before any setup attempt to prevent race conditions
    await new Promise(resolve => setTimeout(resolve, 200));

    return withLock(LOCKS.setupLocks, participantId, async () => {
      try {
        // Get current state
        const currentState = participantStates.current.get(participantId);

        // Don't proceed if we're already in active state
        if (currentState?.setupState === 'active') {
          return;
        }

        // Ensure any existing cleanup is completed first
        if (currentState?.setupState === 'cleaning-up') {
          await new Promise(resolve => setTimeout(resolve, 500));
        }

        // Initialize state
        updateParticipantState(participantId, {
          setupState: 'setting-up',
          setupAttempts: (currentState?.setupAttempts || 0) + 1,
          track: audioTrack,
          audioEnabled: true
        });

        // Validate audio track
        if (!audioTrack?.enabled || audioTrack.readyState !== 'live') {
          if (retryCount < CONFIG.MAX_RETRIES) {
            const delay = CONFIG.RETRY_DELAY * Math.pow(2, retryCount);
            setTimeout(() => {
              if (participant.audioEnabled && participant.audioTrack) {
                void setupAudioProcessing(participant, participant.audioTrack, retryCount + 1);
              }
            }, delay);
          }
          updateParticipantState(participantId, { setupState: 'idle' });
          return;
        }

        // Clean up any existing audio context/processor
        const existingContext = audioContexts.current.get(participantId);
        if (existingContext) {
          try {
            await existingContext.close();
          } catch (error) {
            // Ignore close errors
          }
          audioContexts.current.delete(participantId);
        }

        // Create new audio context
        const context = new AudioContext({
          sampleRate: CONFIG.SAMPLE_RATE,
          latencyHint: 'interactive'
        });

        await context.resume();
        audioContexts.current.set(participantId, context);

        const moduleScript = `
        class TalkTimeProcessor extends AudioWorkletProcessor {
          constructor(options) {
            super();
            this.buffer = new Float32Array(${CONFIG.BUFFER_SIZE});
            this.position = 0;
            this.lastProcessTime = Date.now();
            
            const processorOptions = options.processorOptions || {};
            this.participantId = processorOptions.participantId;
            this.userType = processorOptions.userType;
            this.userId = processorOptions.userId;
            this.classId = processorOptions.classId;
          }

          process(inputs, outputs) {
            const input = inputs[0]?.[0];
            if (!input?.length) {
              this.port.postMessage({ type: 'error', message: 'No input data' });
              return true;
            }
            
            const hasAudio = input.some(sample => Math.abs(sample) > 0.01);
            if (!hasAudio) {
              return true;
            }

            const currentTime = Date.now();
            
            for (let i = 0; i < input.length; i++) {
              if (this.position < this.buffer.length) {
                this.buffer[this.position++] = Math.max(-1, Math.min(1, input[i]));
              }
            }

            if (this.position >= this.buffer.length || 
                (currentTime - this.lastProcessTime >= 10000 && this.position > 0)) {
              this.port.postMessage({
                buffer: this.buffer.slice(0, this.position),
                participantId: this.participantId,
                userType: this.userType,
                userId: this.userId
              });
              
              this.position = 0;
              this.lastProcessTime = currentTime;
            }

            return true;
          }
        }

        registerProcessor('talk-time-processor-${participantId}', TalkTimeProcessor);
      `;

        const blob = new Blob([moduleScript], { type: 'text/javascript' });
        const scriptUrl = URL.createObjectURL(blob);

        try {
          await context.audioWorklet.addModule(scriptUrl);
          const stream = new MediaStream([audioTrack]);
          const source = context.createMediaStreamSource(stream);
          if (source.mediaStream.getAudioTracks().length === 0) {
            logger.error('No audio tracks in MediaStreamSource', { participantId, classId });
            throw new Error('No audio tracks available');
          }


          const processor = new AudioWorkletNode(context, `talk-time-processor-${participantId}`, {
            numberOfInputs: 1,
            numberOfOutputs: 1,
            channelCount: 1,
            channelCountMode: 'explicit',
            channelInterpretation: 'discrete',
            processorOptions: {
              participantId,
              userType,
              userId: participant.customParticipantId,
              classId
            }
          });

          processor.port.onmessage = (event) => {
            if (event.data.buffer) {
              void processBuffer(
                event.data.participantId,
                new Float32Array(event.data.buffer),
                event.data.userType,
                event.data.userId
              );
            }
          };

          // Clear old connections first
          await processor.port.start();
          processor.onprocessorerror = (err) => {
            logger.error('Processor error', { error: err, participantId, classId });
          };

          // Then make connections
          source.disconnect();
          processor.disconnect();
          source.connect(processor);

          audioProcessors.current.set(participantId, processor);
          participantTracks.current.set(participantId, audioTrack);

          // Set state to active only after everything is connected
          updateParticipantState(participantId, {
            setupState: 'active',
            audioEnabled: true,
            track: audioTrack
          });

        } finally {
          URL.revokeObjectURL(scriptUrl);
        }
      } catch (error) {
        updateParticipantState(participantId, { setupState: 'idle' });

        if (retryCount < CONFIG.MAX_RETRIES) {
          const delay = CONFIG.RETRY_DELAY * Math.pow(2, retryCount);
          setTimeout(() => {
            if (participant.audioEnabled && participant.audioTrack) {
              void setupAudioProcessing(participant, participant.audioTrack, retryCount + 1);
            }
          }, delay);
        }
      }
    }, classId);
  };

  useEffect(() => {
    if (!meeting) {
      logger.error('Meeting not available', { classId });
      return;
    }

    const participantCount = meeting.participants?.joined?.size;
    logger.info('Meeting state', {
      participantCount,
      selfId: meeting.self.id,
      hasJoinedParticipants: !!joinedParticipants?.length,
      classId
    });

    if (!joinedParticipants?.length) {
      logger.info('No joined participants available', { classId });
      return;
    }

    // Keep track of current participants for cleanup
    const currentParticipantIds = new Set(joinedParticipants.map(p => p.id));

    // Find participants that have left
    const removedParticipantIds = Array.from(handledParticipants.current)
      .filter(id => !currentParticipantIds.has(id));

    // Clean up only removed participants
    // In your useEffect where you handle removed participants
    removedParticipantIds.forEach(async participantId => {
      handledParticipants.current.delete(participantId);
      const participant = joinedParticipants.find(p => p.id === participantId);
      const userType = participant?.presetName === 'group_call_host' ? 'tutor' : 'student';
      const cleanupId = `${userType}-${classId}-${participantId.split('-').pop()}`;

      // Immediate cleanup of all resources
      const processor = audioProcessors.current.get(cleanupId);
      if (processor) {
        processor.disconnect();
        processor.port.close();
        audioProcessors.current.delete(cleanupId);
      }

      const context = audioContexts.current.get(cleanupId);
      if (context) {
        await context.close();
        audioContexts.current.delete(cleanupId);
      }

      // Clear all other references
      participantStates.current.delete(cleanupId);
      lastProcessTimeMap.current.delete(cleanupId);
      participantTracks.current.delete(cleanupId);

      // Call cleanup for any remaining cleanup tasks
      await cleanupParticipantAudio(cleanupId);
    });

    // Setup audio update handlers for new participants only
    const audioUpdateHandlers = new Map<string, (payload: { audioEnabled: boolean; audioTrack: MediaStreamTrack }) => void>();

    joinedParticipants.forEach(participant => {
      if (!participant?.id || participant.id === meeting.self.id) {
        logger.info('Skipping invalid participant or self', {
          participantId: participant?.id,
          isSelf: participant?.id === meeting.self.id,
          classId
        });
        return;
      }

      if (handledParticipants.current.has(participant.id)) {
        logger.info('Participant already handled', { participantId: participant.id, classId });
        return;
      }

      logger.info('Setting up participant', {
        participantId: participant.id,
        hasCustomId: !!participant.customParticipantId,
        audioEnabled: participant.audioEnabled,
        presetName: participant.presetName,
        isInitialSetup: true,
        classId
      });

      handledParticipants.current.add(participant.id);

      // Create audio update handler
      const audioUpdateHandler = async (payload: { audioEnabled: boolean; audioTrack: MediaStreamTrack }) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
        const participantId = `${userType}-${classId}-${participant.customParticipantId?.split('-').pop()}`;

        logger.info('Audio update event received', {
          participantId,
          audioEnabled: payload.audioEnabled,
          hasAudioTrack: !!payload.audioTrack,
          trackId: payload.audioTrack?.id,
          trackState: payload.audioTrack?.readyState,
          trackEnabled: payload.audioTrack?.enabled,
          classId
        });

        try {
          // Handle mute state
          if (!payload.audioEnabled || !payload.audioTrack?.enabled) {
            const processor = audioProcessors.current.get(participantId);
            if (processor) {
              try {
                processor.disconnect();
                processor.port.close();
                audioProcessors.current.delete(participantId);
              } catch (error) {
                logger.error('Error disconnecting processor', { error, participantId, classId });
              }
            }

            const context = audioContexts.current.get(participantId);
            if (context) {
              try {
                await context.close();
                audioContexts.current.delete(participantId);
              } catch (error) {
                logger.error('Error closing context', { error, participantId, classId });
              }
            }

            updateParticipantState(participantId, {
              setupState: 'idle',
              audioEnabled: false,
              track: null
            });

            await cleanupParticipantAudio(participantId);
            return;
          }

          // Handle unmute with valid track
          if (payload.audioEnabled &&
            payload.audioTrack?.enabled &&
            payload.audioTrack?.readyState === 'live') {

            const currentState = participantStates.current.get(participantId);

            // Always setup if we're not in active state
            if (currentState?.setupState !== 'active') {
              logger.info('Setting up audio after unmute', { participantId, classId });

              // Reset state to idle first
              updateParticipantState(participantId, {
                setupState: 'idle',
                audioEnabled: true,
                track: payload.audioTrack
              });

              // Ensure a small delay before setup
              await new Promise(resolve => setTimeout(resolve, 100));

              // Start new setup
              await setupAudioProcessing(participant, payload.audioTrack, 0);
            }
          }
        } catch (error) {
          logger.error('Audio update error', { participantId, error, classId });
          // Cleanup on error
          const processor = audioProcessors.current.get(participantId);
          if (processor) {
            processor.disconnect();
            processor.port.close();
            audioProcessors.current.delete(participantId);
          }
          updateParticipantState(participantId, {
            setupState: 'idle',
            audioEnabled: false,
            track: null
          });
        }
      };

      audioUpdateHandlers.set(participant.id, audioUpdateHandler);
      participant.on('audioUpdate', audioUpdateHandler);

      // Initial setup if audio is enabled
      if (participant.audioEnabled && participant.audioTrack) {
        void setupAudioProcessing(participant, participant.audioTrack, 0);
      }
    });

    // Cleanup function
    return () => {
      removedParticipantIds.forEach(participantId => {
        const handler = audioUpdateHandlers.get(participantId);
        if (handler) {
          const participant = joinedParticipants.find(p => p.id === participantId);
          if (participant) {
            participant.off('audioUpdate', handler);
          }
          audioUpdateHandlers.delete(participantId);
        }
      });
    };
  }, [joinedParticipants.length]);

  // Cleanup effect for component unmount
  useEffect(() => {
    return () => {
      const cleanupPromises = Array.from(audioContexts.current.keys())
        .filter(participantId => !cleanupInProgress.current.get(participantId))
        .map(participantId => cleanupParticipantAudio(participantId));

      void Promise.all(cleanupPromises).then(() => {
        logger.info('All participants cleaned up during unmount', { classId });
      }).catch(error => {
        logger.error('Error during unmount cleanup', { error, classId });
      });
    };
  }, []); // Empty dependency array - only runs on unmount

  return null;
};

export default useStudentTalkTime;