import { Socket } from 'socket.io-client';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { PRESET_TYPE } from 'configs/meeting';
import useMeeting from 'hooks/useMeeting';
import { useMeetingStore } from 'lib/meeting-store';
import { useStudentTalkTime } from 'hooks';
import { detectEmotionsFromStream } from 'utils';
import { NODE_ENV } from 'configs';

// Type for tracking emotion detection cleanups
type CleanupMap = Map<string, () => void>;

export default function ActiveSpeaker(
  props: Omit<HTMLAttributes<HTMLDyteParticipantTileElement>, 'style'> & {
    isSmall?: true;
    socket: Socket | undefined;
  },
) {
  const { isSmall, socket } = props;
  const { meeting, classId, joinedParticipants, socketUser, tutorId } = useMeeting();
  const [size, states] = useMeetingStore((s) => [s.size, s.states]);
  const [isSocketConnected, setIsSocketConnected] = useState(socket?.connected ?? false);

  // Track both processed participants and their cleanup functions
  const cleanupFunctionsRef = useRef<CleanupMap>(new Map());

  // Socket connection monitoring
  useEffect(() => {
    if (!socket) return;

    const handleConnect = () => {
      console.log('Socket connected:', socket.id);
      setIsSocketConnected(true);
    };

    const handleDisconnect = (reason: string) => {
      console.log('Socket disconnected:', reason);
      setIsSocketConnected(false);
    };

    setIsSocketConnected(socket.connected);

    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    };
  }, [socket]);

  // Participant emotion detection management
  useEffect(() => {
    if (!isSocketConnected || socketUser === PRESET_TYPE.VIEWER || NODE_ENV !== 'prod') return;

    // Get current participant IDs
    const currentParticipantIds = new Set(
      joinedParticipants?.map((p) => p.customParticipantId) || [],
    );

    // Cleanup participants who left
    for (const [participantId, cleanup] of cleanupFunctionsRef.current.entries()) {
      if (!currentParticipantIds.has(participantId)) {
        cleanup();
        cleanupFunctionsRef.current.delete(participantId);
      }
    }

    // Start detection for new participants
    joinedParticipants?.forEach(async (participant) => {
      if (!participant?.videoTrack || !participant.customParticipantId) return;

      // If already processing this participant, skip
      if (cleanupFunctionsRef.current.has(participant.customParticipantId)) return;

      const userType = participant.presetName === 'group_call_host' ? 'tutor' : 'student';
      const userId = participant.customParticipantId.split('-').pop();

      if (!userId) {
        console.error('Invalid participant ID format:', participant.customParticipantId);
        return;
      }

      try {
        const cleanup = await detectEmotionsFromStream(
          participant.videoTrack,
          socket,
          userType,
          userId,
          String(classId),
        );

        if (cleanup) {
          cleanupFunctionsRef.current.set(participant.customParticipantId, cleanup);
        }
      } catch (error) {
        console.error('Failed to start emotion detection:', error);
      }
    });

    // Cleanup on unmount
    return () => {
      for (const cleanup of cleanupFunctionsRef.current.values()) {
        cleanup();
      }
      cleanupFunctionsRef.current.clear();
    };
  }, [joinedParticipants.length, isSocketConnected, socketUser]);

  useEffect(() => {
    if (!isSocketConnected) {
      // Clean up all emotion detections when socket disconnects
      for (const cleanup of cleanupFunctionsRef.current.values()) {
        cleanup();
      }
      cleanupFunctionsRef.current.clear();
    }
  }, [isSocketConnected]);

  // Student talk time tracking
  if (socketUser !== PRESET_TYPE.VIEWER) {
    useStudentTalkTime();
  }

  // Message handler
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      const { data } = event;
      if (data?.type === 'SEND_MESSAGE') {
        try {
          meeting.participants.broadcastMessage('tutorNudge', {
            data: event?.data?.payload?.data,
          });
        } catch (error) {
          console.error('Failed to send message:', error);
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [tutorId, meeting.participants]);

  return (
    <div className='w-full h-screen bg-gray-900'>
      <div className='w-full h-full p-2'>
        <div
          className={`grid w-full h-full gap-2 ${
            joinedParticipants?.length > 2 ? 'grid-cols-2' : 'grid-cols-1 place-items-center'
          }`}
        >
          {joinedParticipants?.map((participant) => (
            <div
              key={participant?.customParticipantId || participant?.id}
              className={`${joinedParticipants?.length === 1 ? 'w-full h-1/2' : 'w-full h-full'}`}
            >
              <DyteParticipantTile
                key={participant.id}
                participant={participant}
                meeting={meeting}
                size={isSmall ? 'lg' : size}
                states={states}
                {...props}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                className='!w-full !h-full rounded-lg shadow-lg overflow-hidden'
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
