import { datadogLogs } from '@datadog/browser-logs';
import { Socket } from 'socket.io-client';

const FRAME_ENABLED_CLASS_IDS = import.meta.env.VITE_EMOTION_ENABLED_CLASS_IDS?.split(',').map(Number) || [];
const FRAME_ENABLED_STUDENT_IDS = import.meta.env.VITE_EMOTION_ENABLED_STUDENT_IDS?.split(',').map(Number) || [];

export async function detectEmotionsFromStream(
  videoStream: MediaStreamTrack,
  socket: Socket | undefined,
  userType: string,
  userId?: string,
  classId?: string,
): Promise<() => void> {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const video = document.createElement('video');
  video.autoplay = true;
  video.muted = true;

  if (!context) {
    console.error('Failed to get canvas context');
    return () => { };
  }

  const shouldIncludeFrame =
    FRAME_ENABLED_CLASS_IDS.includes(Number(classId)) &&
    (FRAME_ENABLED_STUDENT_IDS.includes(Number(userId)));

  video.srcObject = new MediaStream([videoStream]);


  const MAX_EMISSIONS_PER_MINUTE = 60;  // One emission per second
  const emissionTimes: number[] = [];

  if (!userId) {
    console.error('Missing userId for emotion detection');
    return () => { };
  }


  return new Promise((resolve) => {
    video.onloadedmetadata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.play();

      const interval = setInterval(async () => {
        const currentTime = Date.now();

        // Remove emission times older than 1 minute for this participant
        const oneMinuteAgo = currentTime - 60000;
        while (emissionTimes.length && emissionTimes[0] < oneMinuteAgo) {
          emissionTimes.shift();
        }

        // Check if this participant has exceeded their rate limit
        if (emissionTimes.length >= MAX_EMISSIONS_PER_MINUTE) {
          console.debug(`Rate limit reached for participant ${userId}`);
          return;
        }
        const istTime = new Date();
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
        const uint8ArrData = new Uint8Array(imgData.data);
        try {
          const detectedEmotions = await window.facialEye.test_emotion_image(
            uint8ArrData,
            canvas.width,
            canvas.height,
            true,
          );

          // Calculate additional fields
          detectedEmotions.confusion = detectedEmotions?.anger || 0;
          detectedEmotions.engagement =
            detectedEmotions?.anger +
            detectedEmotions?.contempt +
            detectedEmotions?.disgust +
            detectedEmotions?.fear +
            detectedEmotions?.happy +
            detectedEmotions?.neutral +
            detectedEmotions?.sadness +
            detectedEmotions?.surprise || 0;
          detectedEmotions.valence =
            (detectedEmotions?.confusion * -0.51 +
              detectedEmotions?.disgust * -0.6 +
              detectedEmotions?.fear * -0.64 +
              detectedEmotions?.happy * 0.81 +
              detectedEmotions?.sadness * -0.63 +
              detectedEmotions?.surprise * 0.4 +
              detectedEmotions?.neutral * 0.15 +
              detectedEmotions?.contempt * -0.55) /
            4.29;
          detectedEmotions.arousal =
            (detectedEmotions?.confusion * 0.59 +
              detectedEmotions?.disgust * 0.35 +
              detectedEmotions?.fear * 0.61 +
              detectedEmotions?.happy * 0.51 +
              detectedEmotions?.sadness * 0.29 +
              detectedEmotions?.surprise * 0.68 +
              detectedEmotions?.neutral * 0.15 +
              detectedEmotions?.contempt * 0.4) /
            3.58;

          detectedEmotions.timestamp = istTime.toISOString();
          detectedEmotions.userId = Number(userId);
          detectedEmotions.classId = Number(classId);
          detectedEmotions.userType = userType;

          if (shouldIncludeFrame) {
            detectedEmotions.frame = canvas.toDataURL('image/jpeg', 0.8);
          }

          type EmotionType = {
            anger: number;
            contempt: number;
            disgust: number;
            fear: number;
            happy: number;
            neutral: number;
            sadness: number;
            surprise: number;
          };

          const emotions: EmotionType = {
            anger: detectedEmotions?.anger,
            contempt: detectedEmotions?.contempt,
            disgust: detectedEmotions?.disgust,
            fear: detectedEmotions?.fear,
            happy: detectedEmotions?.happy,
            neutral: detectedEmotions?.neutral,
            sadness: detectedEmotions?.sadness,
            surprise: detectedEmotions?.surprise,
          };

          const hasEmotionFields = Object.keys(emotions).some(
            (key) => detectedEmotions[key as keyof EmotionType] !== undefined,
          );

          if (hasEmotionFields) {
            const dominantEmotion = Object.keys(emotions).reduce((a, b) =>
              emotions[a as keyof EmotionType] > emotions[b as keyof EmotionType] ? a : b,
            );
            detectedEmotions.emotion = dominantEmotion;
          } else {
            detectedEmotions.emotion = '';
          }

          if (socket && socket.connected) {
            socket.emit('emotionData', JSON.stringify({
              data: detectedEmotions
            }));
            emissionTimes.push(currentTime);
          }
        } catch (error) {
          if (error instanceof Error && error?.message && error?.stack && typeof error !== 'number') {
            datadogLogs.logger.info(
              `Error detecting emotions: ${error.message}, stack: ${error.stack}`,
            );
          }
        }
      }, 1000);

      videoStream.addEventListener('ended', () => clearInterval(interval));

      const cleanup = () => {
        clearInterval(interval);
        video.srcObject = null;
        video.pause();
        video.remove();
        canvas.remove();
        socket?.removeAllListeners('message');
      };

      // Resolve with cleanup function
      resolve(cleanup);
    };
  });
}